import React from "react"

// Components
import { Link, graphql } from "gatsby"

// My Components
import Layout from "../layouts"


const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext

  return (
    <Layout isSubMenuOpen={true} openAccountLink={true}>
      <div>
        <h1>All Articles That With the Tag: {tag}</h1>

        <Link to="/resources/wellness/articles/">All tags</Link>
      </div>
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMdx(
      limit: 2000
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          slug
          frontmatter {
            description
            title
          }
        }
      }
    }
  }
`